import React, { useState, useEffect, useCallback } from 'react';
import images from '../assets/images'; // Adjust the path as necessary
import './ImageCycler.css'; // Adjust the path as necessary
import ReactGA from 'react-ga4';

function ImageCycler({ fortunes }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentFortuneIndex, setCurrentFortuneIndex] = useState(0); // Define state for current fortune index
  const [animate, setAnimate] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  console.log("currentImageIndex: ", currentImageIndex);
  console.log("fortunes[currentFortuneIndex]", fortunes[currentFortuneIndex]);  

  const pickFortune = () => {
    // const randomFortuneIndex = Math.floor(Math.random() * fortunes.length);
    // const randomImageIndex = Math.floor(Math.random() * images.length); // Pick a random image    
    const nextFortuneIndex = (currentFortuneIndex + 1) % fortunes.length;       
    const nextImageIndex = (currentImageIndex + 1) % images.length;
    setCurrentFortuneIndex(nextFortuneIndex);
    setCurrentImageIndex(nextImageIndex); // Use this for displaying the image
    setImageLoaded(false)
    triggerAnimation();

    ReactGA.event({
        category: 'Image Cycler',
        action: 'Click',
        label: 'Image Clicked' // Optional, you can also include dynamic labels like the name of the image or fortune
    });
  };

  const triggerAnimation = useCallback(() => {
    if (imageLoaded) {
        setAnimate(true);
        setTimeout(() => setAnimate(false), 1000); // Assuming the animation duration is 1s
    }
  }, [imageLoaded]);

  useEffect(() => {
    triggerAnimation(); // Trigger animation on component mount
  }, [triggerAnimation]);


  return (
    <div className="image-cycler-container">
      <div className="image-cycler">
        <div className="cycling-image-wrapper">
          <div className="cycling-image" onClick={pickFortune}>
            <img
              src={images[currentImageIndex]}
              alt="FortuneCookie Cafe"
              onLoad={() => setImageLoaded(true)}
              className={`cycling-image ${animate ? 'animate' : ''}`}
            />
            <p className="clickable-hint mobile-only">Click the image for a new fortune!</p>
          </div>
          <p key={currentImageIndex} className={`fortune-text-container ${animate ? 'animate-reveal' : ''}`}>
            {fortunes[currentImageIndex]}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ImageCycler;