// import logo from './logo.svg';
import './App.css';
// import FortuneCookie from './components/FortuneCookie';
// import useGA4 from './hooks/useGA4';
import { fortunes } from './data/Fortunes';
import ImageCycler from './components/ImageCycler';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-TN8VD2YBK8'); // Replace 'YOUR_MEASUREMENT_ID' with your actual Measurement ID

function App() {
  // useGA4('G-TN8VD2YBK8');
  return (
    <div className="App">
      <header className="App-header">
      <h1>FortuneCookie Cafe</h1>      
      </header>

      <div className="app-content">
        <ImageCycler fortunes={fortunes} />
        {/* <FortuneCookie /> */}
        {/* Any other content */}
      </div>
      <footer className="app-footer">
      <span>developed by <a href="https://iterative.day" target="_blank" rel="noopener noreferrer">iterative.day</a></span>
      </footer>

    </div>
  );
}

export default App;
