export const fortunes = [
    "What do you call a bear with no teeth? A gummy bear!",
    "Why did the scarecrow win an award? Because he was outstanding in his field!",
    "What do you call a fake noodle? An impasta!",
    "Want to hear a pizza joke? Never mind, it's too cheesy!",
    "Why don't scientists trust atoms? Because they make up everything!",
    "What do you call a belt made of watches? A waist of time!",
    "Why couldn't the bicycle stand up by itself? It was two tired!",
    "What did one wall say to the other wall? I'll meet you at the corner!",
    "Why did the coffee file a police report? Because it got mugged!",
    "What do you call a dinosaur that crashes his car? Tyrannosaurus Wrecks!",
    "Why did the cookie go to the doctor? Because it was feeling crumbly!",
    "What do you call a pony with a cough? A little hoarse!",
    "What do you get when you cross a snowman with a vampire? Frostbite!",
    "Why was the math book sad? Because it had too many problems!",
    "What do you call a boomerang that doesn't come back? A stick!",
    "Why don't skeletons fight each other? They don't have the guts!",
    "What do you call cheese that isn't yours? Nacho cheese!",
    "What do you call a bear with no ears? A \"B\"!",
    "Why don't oysters donate to charity? Because they're shellfish!",
    "What do you get when you cross a sheep and a kangaroo? A woolly jumper!",
    "Why did the cookie go to the hospital? Because it felt crumbly!",
    "What did the grape say when it got stepped on? Nothing, but it let out a little wine!",
    "What do you call a fake stone in Ireland? A shamrock!",
    "Why did the scarecrow win an award? Because he was out standing in his field!",
    "What do you get when you cross a snowman with a shark? Frostbite!",
    "What's brown and sticky? A stick!",
    "Why don't eggs tell jokes? They'd crack each other up!",
    "What do you call a dinosaur that is a noisy sleeper? A Brontosnorus.",
    "Why did the banana go to the doctor? Because it wasn't peeling well!",
    "What do you call a cow with no legs? Ground beef!",
    "What do you call a cow on a trampoline? A milk shake!",
    "Why did the math book look so sad? Because it had too many problems.",
    "What do you get when you cross a centipede with a parrot? A walkie-talkie!",
    "Why do programmers prefer dark mode? Because light attracts bugs!",
    "Why are fish so smart? Because they live in schools!",
    "What do you call a sleeping bull? A bulldozer!",
    "What do you call a bear with no teeth? A gummy bear!",
    "Why did the computer go to the doctor? Because it had a virus!",
    "What do you call fake spaghetti? An Impasta!",
    "Why don't skeletons watch scary movies? They just don't have the guts.",
    "What do you call a pig that does karate? A pork chop!",
    "Why don't scientists trust atoms? Because they make up everything.",
    "What do you call a fish wearing a bowtie? So-fish-ticated!",
    "Your screen time is valuable. Spend it on what makes you happy and smart.",
    "Kindness is like sugar, it makes life taste a little sweeter.",
    "You will soon land a great parking spot. Don't wait, it's your destiny... or just a spot.",
    "You will achieve great things in life, and occasionally find the matching sock.",
    "A day without sunshine is like... well, night.",
    "You will make a grammatical error that goes unnoticed. It's not the end of the world, but you should of seen it coming.",
    "You will learn to play an instrument, making you a 'noteworthy' individual.",
    "You'll find happiness with a new love. Yep, you're about to fall into a deep, lasting relationship with pizza.",
    "Invest in a vacuum cleaner; it might not seem exciting, but it's an investment that truly sucks.",
    "You have rice in your teeth. Just kidding, but you checked, didn't you?",
    "You will soon eat a piece of paper. Oh wait, you just did.",
    "An unexpected visitor will soon bring you joy. Keep an eye out for the dog you petted last week.",
    "You will discover that laughter is the best medicine. Unfortunately, it does nothing for dental work.",
    "You're going to be hungry again in one hour. Probably because you just ate a cookie, not a meal.",
    "You will soon encounter a man of mystery. He goes by the name 'WiFi' and sometimes he's just not there.",
    "If at first, you don't succeed, check if there's a prize for failure.",
    "Your talents will be recognized and suitably rewarded. By talents, we mean your ability to binge-watch an entire series in one sitting.",
    "Remember, if life gives you melons... you might be dyslexic.",
    "You will soon have an out-of-money experience.",
    "Life is like a sewer. What you get out of it depends on what you put into it. And you thought this was going in a different direction.",
    "You will soon wake up feeling energized. Oh, sorry, that was meant for your neighbor. You'll probably just hit snooze.",
    "You'll achieve a great balance in life, especially after those balance training classes.",
  ];